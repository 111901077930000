<template>
  <v-card :elevation="0">
    <v-sheet shaped color="paper" class="pa-1" elevation="3">
      <v-card-title
        :class="textSizeSmall + ' charcoal paper--text px-2 py-1 rounded'"
      >
        {{ mode.name }}
        <v-spacer />
        <v-switch
          color="progressActive "
          class="mr-2"
          title="Fine Scale?"
          v-model="finescale"
          @click="setScale"
        >
          <template v-slot:label>
            <span class="silver--text">Fine Scale?</span>
          </template>
        </v-switch>
      </v-card-title>
      <v-card-text
        v-if="max > 0"
        :class="textSizeXSmall + ' silver--text pb-0 pl-0'"
      >
        <v-spacer />
        Min: {{ min }} {{ unitLabel }}, Max: {{ max }} {{ unitLabel }}, Avg:
        {{ avg }} {{ unitLabel }}
        <v-spacer />
      </v-card-text>
      <v-card-title v-else :class="textSizeXSmall + ' silver--text py-0'">
        No Data Available.
      </v-card-title>
      <v-select
        class="mb-n5"
        filled
        v-model="groupby"
        color="success "
        item-color="success"
        return-object
        prepend-inner-icon="mdi-calendar"
        :items="interval"
        label="Interval"
        item-text="text"
        item-value="value"
        @change="loadItems"
        :menu-props="{
          closeOnClick: true,
          maxHeight: 280,
          transition: 'fab-transition'
        }"
      />

      <MyLineChart
        v-if="loaded"
        :chartdata="chartdata"
        :options="options"
        :key="switchnum"
      />
    </v-sheet>
  </v-card>
</template>

<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const MyLineChart = () =>
  import(/* webpackPrefetch: true */ '@/components/charts/MyLineChart.vue')
export default {
  components: { MyLineChart },
  mixins: [util],
  data: () => ({
    chartdata: {},
    options: {},
    items: [],
    weights: [],
    values: [],
    labels: [],
    loaded: false,
    min: 0,
    max: 200,
    avg: 0,
    offsset: 10,
    switchnum: 0,
    stepsize: 50,
    finescale: false,
    groupby: { text: 'By Month', value: 'monthname' },
    interval: [
      { text: 'By Month', value: 'monthname' },
      { text: 'By Week', value: 'week' },
      { text: 'Day to Day', value: 'day' }
    ]
  }),
  props: {
    mode: {
      type: Object
    },
    selection: {
      type: Object
    },
    metric: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.selection) this.groupby = this.selection
    this.loadItems()
  },
  validations: {},
  computed: {
    ...appConfig,
    ...builder,
    unitLabel() {
      let unit = ''
      if (this.mode.filter == 'power_rel' || this.mode.filter == 'cft_rel')
        unit = 'SELFS'
      else unit = this.unit(this.metric).toString()
      return unit
    }
  },
  watch: {
    metric: function() {
      this.loadItems()
    },
    finescale: function() {
      this.loadItems()
    },
    mode: function() {
      this.loadItems()
    }
  },
  methods: {
    setScale() {
      this.stepsize = this.finescale ? 20 : 50
    },
    loadItems() {
      {
        this.$emit('group_by', this.groupby)
        let liftingBase = '/stats/lifting/powerlifting/'
        let url = this.baseURL + liftingBase + this.groupby.value + ':all'

        return axios.get(url, {}).then(response => {
          if (response.status == 200) {
            this.items = response.data.data

            let val = 0
            let t = this
            if (this.metric) {
              this.values = this.items.map(function(obj) {
                if (t.mode.filter == 'power_abs')
                  val = parseFloat(obj.powerlifting_metric)
                if (t.mode.filter == 'cft_abs') val = parseFloat(obj.cft_metric)
                if (t.mode.filter == 'power_rel')
                  val = parseFloat(obj.selfs_powerlifting)
                if (t.mode.filter == 'cft_rel') val = parseFloat(obj.selfs_cft)
                return t.twoDigits(val)
              })
            } else {
              this.values = this.items.map(function(obj) {
                if (t.mode.filter == 'power_abs')
                  val = parseFloat(obj.powerlifting_imperial)
                if (t.mode.filter == 'cft_abs')
                  val = parseFloat(obj.cft_imperial)
                if (t.mode.filter == 'power_rel')
                  val = parseFloat(obj.selfs_powerlifting)
                if (t.mode.filter == 'cft_rel') val = parseFloat(obj.selfs_cft)
                return t.twoDigits(val)
              })
            }
            this.labels = this.items.map(function(obj) {
              return obj.label
            })
            this.min = this.arrayMin(this.values)
            this.max = this.arrayMax(this.values)
            this.avg = this.arrayAvg(this.values)

            this.chartdata = {
              labels: this.labels,
              datasets: [
                {
                  label: this.groupby.text,
                  backgroundColor: '#fafafa',
                  borderColor: '#8BC34A',
                  borderWidth: 1,
                  data: this.values,
                  radius: 4,
                  pointHoverRadius: 15,
                  pointHoverBorderWidth: 10,
                  pointHoverBorderColor: '#212b31',
                  fill: false,
                  pointBackgroundColor: '#53a653',
                  lineTension: 0.1
                  //pointStyle: 'rectRot'
                },
                {
                  label: 'Avg.',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.avg
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: 'orange',
                  borderWidth: 1,
                  border: '1'
                },
                {
                  label: 'Max',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.max
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: '#b0e8ff',
                  borderWidth: 1,
                  border: '1'
                },
                {
                  label: 'Min',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.min
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: '#ff63c5',
                  borderWidth: 1,
                  border: '1'
                }
              ]
            }
            this.options = {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                labels: {
                  boxWidth: 10
                  // usePointStyle: true,
                }
              },
              scales: {
                yAxes: [
                  {
                    suggestedMin: Math.max(this.min - this.offsset, 0),
                    suggestedMax: this.max + this.offsset,
                    ticks: {
                      stepSize: this.stepsize,
                      fontColor: '#212b31',
                      callback: function(label) {
                        let unit = ''
                        if (
                          t.mode.filter == 'power_rel' ||
                          t.mode.filter == 'cft_rel'
                        )
                          unit = 'SELFS'
                        else unit = t.unit(t.metric).toString()
                        return label + ' ' + unit
                      }
                    }
                  }
                ]
              }
            }
            this.$emit('get_max', this.max)
            this.loaded = true
            this.switchnum++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
      }
    }
  }
}
</script>
